var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("p", { staticClass: "gray mt2 f4" }, [
        _vm._v("Enter an email address to generate invite code:"),
      ]),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.emailId,
            expression: "emailId",
          },
        ],
        staticClass: "bg-adori-gray white pa2 code br2 w5",
        domProps: { value: _vm.emailId },
        on: {
          input: function ($event) {
            if ($event.target.composing) {
              return
            }
            _vm.emailId = $event.target.value
          },
        },
      }),
      _c("br"),
      _c("BaseButtonRed", {
        staticClass: "mt3",
        attrs: { text: "Generate", onClick: _vm.saveFeatured },
      }),
      _c("br"),
      _c("div", { staticClass: "mt4" }, [
        _vm.error !== "" && _vm.success === false
          ? _c("textarea", {
              staticClass: "di ml3 f3 bg-red pa2 bw0 black",
              attrs: { rows: "1", cols: "50", disabled: "" },
              domProps: { value: _vm.error },
            })
          : _vm._e(),
        _vm.success
          ? _c("textarea", {
              staticClass: "di ml3 f3 bg-green pa2 bw0 black",
              attrs: { rows: "1", cols: "32", readonly: "" },
              domProps: { value: _vm.error },
            })
          : _vm._e(),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }