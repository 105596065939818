





















import { Vue, Component } from 'vue-property-decorator'
import AdoriService from '@/services/adori'

@Component
export default class ViewGenerateInvite extends Vue {
  emailId = ''
  error = ''
  success = false

  isValidEmail() {
    /* eslint-disable no-useless-escape */
    const re =
      /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
    return re.test(this.emailId)
  }

  async mounted() {
    window.scrollTo(0, 0)
  }

  async saveFeatured() {
    if (this.isValidEmail()) {
      const res: any = await AdoriService.generateInviteCode({
        emailId: this.emailId,
      })

      if (res.response && res.response.status !== 200) {
        this.success = false
        this.error = 'Error: ' + res.response.data.title || res.response.statusText
      } else {
        this.success = true
        this.error = res.inviteCode
      }
    }
  }
}
